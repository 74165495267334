<template>
  <div class="create-content-main">
    <div class="create-form">
      <div class="cpy-2">
        <span class="cpx-4 font-title">簡易メール配信編集</span>
      </div>
      <form class="cpx-15" id="create-content" autocomplete="off">
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label"
            >メール送信者名<span class="textMust">（必須）</span></label
          >
          <div class="cp-4 w-100 pt-3">
            <input class="form-input col-sm-12" v-model="sender_name" />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label"
            >配信先<span class="textMust">（必須）</span>
          </label>
          <div class="cp-4 w-100 pt-3">
            <div class="btn-toggle-active">
              <input
                v-model="delivery_destination"
                class="m-0 d-none"
                type="radio"
                name="delivery_destination_type"
                value="0"
                id="delivery_destination_type_0"
              />
              <label
                for="delivery_destination_type_0"
                :class="
                  delivery_destination == 0
                    ? 'btn-share-freesize-active py-2 px-4'
                    : 'btn-share-freesize py-2 px-4'
                "
                >アドレス指定</label
              >
            </div>
            <div class="btn-toggle-active">
              <input
                v-model="delivery_destination"
                class="m-0 d-none"
                type="radio"
                name="delivery_destination_type"
                value="1"
                id="delivery_destination_type_1"
              />
              <label
                for="delivery_destination_type_1"
                :class="
                  delivery_destination == 1
                    ? 'btn-share-freesize-active py-2 px-4'
                    : 'btn-share-freesize py-2 px-4'
                "
                >コンテンツ所持指定</label
              >
            </div>
            <div class="btn-toggle-active">
              <input
                v-model="delivery_destination"
                class="m-0 d-none"
                type="radio"
                name="delivery_destination_type"
                value="2"
                id="delivery_destination_type_2"
              />
              <label
                for="delivery_destination_type_2"
                :class="
                  delivery_destination == 2
                    ? 'btn-share-freesize-active py-2 px-4'
                    : 'btn-share-freesize py-2 px-4'
                "
                >スラグ所持指定</label
              >
            </div>
            <div class="btn-toggle-active">
              <input
                v-model="delivery_destination"
                class="m-0 d-none"
                type="radio"
                name="delivery_destination_type"
                value="3"
                id="delivery_destination_type_3"
              />
              <label
                for="delivery_destination_type_3"
                :class="
                  delivery_destination == 3
                    ? 'btn-share-freesize-active py-2 px-4'
                    : 'btn-share-freesize py-2 px-4'
                "
                >全員に配信</label
              >
            </div>
          </div>
          <div
            class="cp-4 w-100 d-flex align-items-center"
            v-if="delivery_destination == 0"
          >
            <label style="min-width: 130px; margin: 0px"
              >電子メールを送信:
            </label>
            <div class="w-100">
              <multiselect
                v-model="listUserId"
                placeholder="コンテンツを選択"
                class="w-100 col-lg-6 px-0"
                label="user_email"
                track-by="user_id"
                :options="listUserOptions"
                :multiple="true"
                :taggable="false"
                :showNoResults="false"
                :select-label="'メールアドレス入力'"
                :deselect-label="'メールアドレス入力'"
                :selected-label="'選択された'"
                style="width: 100%"
                :close-on-select="false"
              >
              </multiselect>
            </div>
          </div>
          <div class="cp-4 w-100" v-if="delivery_destination == 1">
            <div
              class="col-sm-12 p-0 mb-5 d-flex"
              v-for="(value, index) in listContentId"
              :key="index"
            >
              <multiselect
                v-model="value.id"
                tag-placeholder="コンテンツ選択 "
                placeholder="コンテンツ選択 "
                label="content_name"
                track-by="content_id"
                :options="listContentOptions"
                :multiple="true"
                :taggable="true"
                :close-on-select="false"
              >
              </multiselect>
              <b-button
                @click="removeListDisplay(index, 'content')"
                style="width: 38px; margin-right: 10px; color: white"
                class="bg-333333 ml-3"
              >
                <font-awesome-icon
                  class="sidebar-icon"
                  :icon="['fas', 'minus']"
                />
              </b-button>
            </div>
            <div
              class="row row-input justify-content-center aligns-items-center"
            >
              <b-icon
                icon="plus"
                class="h1 rounded-circle add-new-description mt-3"
                @click="addContentId()"
                font-scale="3"
                style="color: #fff; background: #333"
              ></b-icon>
            </div>
          </div>
          <div class="cp-4 w-100" v-if="delivery_destination == 2">
            <div
              class="col-sm-12 p-0 mb-5 d-flex"
              v-for="(value, index) in listSlagId"
              :key="index"
            >
              <multiselect
                v-model="value.id"
                tag-placeholder="スラグ選択"
                placeholder="スラグ選択"
                label="slag_name"
                track-by="slag_id"
                :options="listSlagOptions"
                :multiple="true"
                :taggable="true"
                :close-on-select="false"
              >
              </multiselect>
              <b-button
                @click="removeListDisplay(index, 'slag')"
                style="width: 38px; margin-right: 10px; color: white"
                class="bg-333333 ml-3"
              >
                <font-awesome-icon
                  class="sidebar-icon"
                  :icon="['fas', 'minus']"
                />
              </b-button>
            </div>
            <div
              class="row row-input justify-content-center aligns-items-center"
            >
              <b-icon
                icon="plus"
                class="h1 rounded-circle add-new-description mt-3"
                @click="addSlagId()"
                font-scale="3"
                style="color: #fff; background: #333"
              ></b-icon>
            </div>
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label"
            >配信メールタイトル（100文字以内）<span class="textMust"
              >（必須）</span
            ></label
          >
          <div class="cp-4 w-100 pt-3">
            <input class="form-input col-sm-12" v-model="subject" />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label"
            >本文<span class="textMust">（必須）</span></label
          >
          <div class="cp-4 w-100 pt-3">
            <div class="btn-toggle-active">
              <input
                v-model="content_type"
                class="m-0 d-none"
                type="radio"
                name="content_type_radio"
                value="0"
                id="content_type_radio_0"
              />
              <label
                for="content_type_radio_0"
                :class="
                  content_type == 0
                    ? 'btn-share-freesize-active py-2 px-4'
                    : 'btn-share-freesize py-2 px-4'
                "
                >テキストメール</label
              >
            </div>
            <!-- <div class="btn-toggle-active">
              <input
                v-model="content_type"
                class="m-0 d-none"
                type="radio"
                name="content_type_radio"
                value="1"
                id="content_type_radio_1"
              />
              <label
                for="content_type_radio_1"
                :class="
                  content_type == 1
                    ? 'btn-share-freesize-active py-2 px-4'
                    : 'btn-share-freesize py-2 px-4'
                "
                >HTMLメール</label
              >
            </div> -->
            <div v-if="content_type == 0">
              <b-form-textarea v-model="content" rows="11"></b-form-textarea>
            </div>
            <!-- <div v-else>
              <input
                type="file"
                hidden
                ref="selectFile"
                id="idSelectFile"
                accept=".pdf"
                @change="changePDF($event)"
              />
              <vue-editor
                useCustomImageHandler
                @image-added="handleImageAdded"
                @image-removed="handleImageRemove"
                v-model="content"
              ></vue-editor>
            </div> -->
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label"
            >配信タイミング設定<span class="textMust">（必須）</span></label
          >
          <div class="cp-4 w-100 pt-3">
            <div class="btn-toggle-active">
              <input
                v-model="use_timing_mail"
                class="m-0 d-none"
                type="radio"
                name="use_timing_mail_radio"
                value="0"
                id="use_timing_mail_radio_0"
              />
              <label
                for="use_timing_mail_radio_0"
                :class="
                  use_timing_mail == 0
                    ? 'btn-share-freesize-active py-2 px-4'
                    : 'btn-share-freesize py-2 px-4'
                "
                >即時配信</label
              >
            </div>
            <div class="btn-toggle-active">
              <input
                v-model="use_timing_mail"
                class="m-0 d-none"
                type="radio"
                name="use_timing_mail_radio"
                value="1"
                id="use_timing_mail_radio_1"
              />
              <label
                for="use_timing_mail_radio_1"
                :class="
                  use_timing_mail == 1
                    ? 'btn-share-freesize-active py-2 px-4'
                    : 'btn-share-freesize py-2 px-4'
                "
                >日時指定</label
              >
            </div>
            <div class="btn-toggle-active">
              <input
                v-model="use_timing_mail"
                class="m-0 d-none"
                type="radio"
                name="use_timing_mail_radio"
                value="2"
                id="use_timing_mail_radio_2"
              />
              <label
                for="use_timing_mail_radio_2"
                :class="
                  use_timing_mail == 2
                    ? 'btn-share-freesize-active py-2 px-4'
                    : 'btn-share-freesize py-2 px-4'
                "
                >時間指定</label
              >
            </div>
            <div
              v-if="use_timing_mail == 1"
              class="row align-items-center flex-nowrap my-4"
            >
              <div class="col-md-3">
                <date-picker
                  name="date"
                  v-model="datetime"
                  :config="optionsDate"
                  autocomplete="off"
                  placeholder=""
                ></date-picker>
              </div>
            </div>
            <div v-if="use_timing_mail == 2">
              <div class="d-flex align-items-center my-4 w-25">
                <b-form-radio
                  v-model="typeHourMinute"
                  name="some-radios w-auto"
                  :value="0"
                ></b-form-radio>
                <input
                  class="form-control form-control-sm w-25"
                  v-model="minutes"
                  onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                  :disabled="typeHourMinute == 1"
                />
                <span class="col-auto">分後に送信</span>
              </div>
              <div class="d-flex align-items-center my-4 w-25">
                <b-form-radio
                  v-model="typeHourMinute"
                  name="some-radios w-auto"
                  :value="1"
                ></b-form-radio>
                <input
                  class="form-control form-control-sm w-25"
                  v-model="hours"
                  onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                  :disabled="typeHourMinute == 0"
                />
                <span class="col-auto">時間後に配信</span>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-4 py-4">
          <b-button
            @click="CheckUserSend()"
            :disabled="isLoading"
            variant="info"
            class="mr-4"
          >
            <span> 配信先確認 </span>
          </b-button>
          <b-button variant="secondary" class="mr-4" v-on:click="returnList()"
            >戻る</b-button
          >
        </div>
      </form>
    </div>
    <div>
      <b-modal
        id="modal-send-user"
        size="xl"
        modal-class="modal-send-user"
        ref="modal-send-user"
        :hide-footer="true"
      >
        <template #modal-header="{}">
          <input
            placeholder="タイトル検索"
            class="form-control w-50 ml-5"
            style="margin-right: 20%"
            v-model="keyword"
            @keydown.enter.prevent="filterUserSend()"
          />
          <b-button
            size="sm"
            style="background-color: white; border-color: white"
            class="close ml-0"
            @click="closeModal()"
          >
            <i class="fas fa-times"></i>
          </b-button>
        </template>
        <div
          class="page-list-content p-0"
          style="max-height: 60vh; overflow-y: auto; overflow-x: hidden"
        >
          <Tables
            :items="listUserSendOptions"
            :fields="fields"
            :itemsPerPageSelect="{}"
            class="cpx-4"
          >
            <template v-slot:action="{ index }">
              <td class="my-auto pl-2 pr-1">
                <div class="w-5">
                  <CButton
                    class="mx-1 btn-del"
                    square
                    variant="outline"
                    size="sm"
                    v-on:click="removeUserSend(index)"
                  >
                    <span>配信を除外</span>
                  </CButton>
                </div>
              </td>
            </template>
          </Tables>
        </div>
        <div class="cpx-4 mt-4" v-if="dateTimeSend">
          上記の設定で「{{
            dateTimeSend
          }}」に配信します。問題なければ「配信設定」ボタンを押してください
        </div>
        <div class="d-flex justify-content-center py-4">
          <b-button
            @click="CreateTimeEmail()"
            :disabled="isLoading"
            variant="info"
            class="mr-4"
          >
            <span> 配信設定 </span>
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
// import { VueEditor } from "vue2-editor";
import { mapGetters, mapActions } from "vuex";
import { Urls } from "../../utils/urls.js";
import { Api } from "../../utils/http-common.js";
import { Constants } from "../../utils/constants.js";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import { tableFields } from "../../utils/table-fields.js";
import Tables from "../../components/common/Tables";
import moment from "moment";

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});
export default {
  name: "Create_TimingMail",
  components: {
    // VueEditor,
    Tables,
  },
  data() {
    return {
      sender_name: "",
      isLoading: false,
      subject: "",
      content_type: 0,
      content: "",
      delivery_destination: 0,
      use_timing_mail: 0,
      datetime: null,
      minutes: null,
      hours: null,
      dataDetail: {
        id: "",
        shop_id: "",
      },
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      listUserOptions: [],
      listSlagOptions: [],
      listContentOptions: [],
      listUserId: [],
      listSlagId: [{}],
      listContentId: [{}],
      optionsDate: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
      fields: tableFields.MODAL_USER,
      keyword: "",
      dateTimeSend: "",
      dateTimeSendId: null,
      listUserSendOptions: [],
      listUserSendTemp: [],
      typeHourMinute: 0,
      arr_slag_id: [],
      arr_content_id: [],
      arr_user_id: [],
    };
  },
  async created() {
    const { params } = this.$route;
    this.dataDetail.id = params.id;
    this.dataDetail.shop_id = this.shop_id;

    const requestUser = {
      data: {
        shop_id: this.shop_id,
        limit: "all",
      },
      page: 1,
    };
    await this.getListUser(requestUser);
    const requestContent = {
      page: 1,
      data: {
        shop_id: this.shop_id,
        limit: "all",
      },
    };
    await this.getListContents(requestContent);
    const requestSlag = {
      shop_id: this.shop_id,
    };
    await this.getALLlistSlag(requestSlag);
    this.$store.commit("set", ["success", false]);
    this.$store.commit("set", ["message", ""]);
    this.$store.commit("set", ["error", false]);

    if (this.dataDetail.id !== undefined) {
      await this.getTimingMailById(this.dataDetail);
    }
  },
  mounted() {
    // let container = document.getElementsByClassName("ql-toolbar")[0];
    // let item = document.createElement("span");
    // item.classList.add("ql-formats");
    // let input = document.createElement("button");
    // input.addEventListener("click", this.toggleModal);
    // input.setAttribute("type", "button");
    // input.classList.add("ql-test");
    // input.innerHTML = "PDF";
    // item.appendChild(input);
    // container.appendChild(item);
  },
  computed: {
    ...mapGetters([
      "timingMailById",
      "success",
      "message",
      "error",
      "listSlag",
      "listContents",
      "listUser",
      "timingMailUserSend",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.isLoading = false;
      }
    },
    error() {
      if (this.error) {
        this.isLoading = false;
      }
    },
    timingMailById() {
      this.sender_name = this.timingMailById.sender_name;
      this.delivery_destination = this.timingMailById.delivery_destination;
      this.subject = this.timingMailById.subject;
      this.content_type = this.timingMailById.content_type;
      this.content = this.timingMailById.content;
      this.use_timing_mail = this.timingMailById.use_timing_mail;
      this.typeHourMinute = this.timingMailById.type_hour_minute;
      this.datetime = this.timingMailById.datetime;
      this.minutes = this.timingMailById.minutes;
      this.hours = this.timingMailById.hours;
      if (
        this.timingMailById.list_user &&
        this.timingMailById.list_user.length > 0
      ) {
        this.timingMailById.list_user.forEach((item, index) => {
          this.listUser.map((value) => {
            if (value.id == item) {
              this.timingMailById.list_user[index] = {
                user_id: value.id,
                user_email: value.email,
                user_name: value.name,
              };
            }
          });
        });
        this.listUserId = Object.values(this.timingMailById.list_user);
      }
      if (
        this.timingMailById.list_content &&
        this.timingMailById.list_content.length > 0
      ) {
        this.timingMailById.list_content.forEach((item, index) => {
          item.forEach((subItem, subIndex) => {
            this.listContents.map((value) => {
              if (value.id == subItem) {
                item[subIndex] = {
                  content_id: value.id,
                  content_name: value.title,
                };
              }
            });
          });
          item = { id: item };
          this.timingMailById.list_content[index] = item;
        });
        this.listContentId = Object.values(this.timingMailById.list_content);
      }
      if (
        this.timingMailById.list_slag &&
        this.timingMailById.list_slag.length > 0
      ) {
        this.timingMailById.list_slag.forEach((item, index) => {
          item.forEach((subItem, subIndex) => {
            this.listSlag.map((value) => {
              if (value.id == subItem) {
                item[subIndex] = {
                  slag_id: value.id,
                  slag_name: value.slag_name
                    ? value.slag_name
                    : value.slag_name_en,
                };
              }
            });
          });
          item = { id: item };
          this.timingMailById.list_slag[index] = item;
        });
        this.listSlagId = Object.values(this.timingMailById.list_slag);
      }
    },
    listSlag() {
      this.listSlagOptions = this.listSlag.map((item) => ({
        slag_id: item.id,
        slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
      }));
    },
    listContents() {
      this.listContentOptions = this.listContents.map((item) => ({
        content_id: item.id,
        content_name: item.title,
      }));
    },
    listUser() {
      this.listUserOptions = this.listUser.map((item) => ({
        user_id: item.id,
        user_email: item.email,
        user_name: item.name,
      }));
    },
    typeHourMinute: {
      handler: function (value) {
        if (value == 0) {
          this.hours = "";
        } else {
          this.minutes = "";
        }
      },
    },
  },
  methods: {
    ...mapActions({ createTimingMail: "createTimingMail" }),
    ...mapActions({ getALLlistSlag: "getALLlistSlag" }),
    ...mapActions({ getListContents: "getListContents" }),
    ...mapActions({ getListUser: "getListUser" }),
    ...mapActions({ getTimingMailById: "getTimingMailById" }),
    ...mapActions({ updateTimingMailByID: "updateTimingMailByID" }),
    ...mapActions({ checkConditionTimingMail: "checkConditionTimingMail" }),
    async CheckUserSend() {
      this.isLoading = true;
      let shop_id = this.shop_id;
      let error = false;

      if (!this.content || !this.subject) {
        error = true;
      }
      if (this.delivery_destination == 0) {
        if (this.listUserId.length == 0) {
          error = true;
        } else {
          this.listUserId.forEach((item) => {
            if (item.length == 0) {
              error = true;
            } else {
              this.arr_user_id.push(item.user_id);
            }
          });
        }
        this.listUserSendOptions = this.listUserId;
      } else if (
        this.delivery_destination == 1 ||
        this.delivery_destination == 2
      ) {
        if (this.delivery_destination == 2) {
          if (this.listSlagId.length == 0) {
            error = true;
          } else {
            this.listSlagId.forEach((item) => {
              if (item.length == 0 || !item.id || item.id.length == 0) {
                error = true;
              } else {
                let subSlag = [];
                item.id.forEach((subItem) => {
                  subSlag.push(subItem.slag_id);
                });
                this.arr_slag_id.push(subSlag);
              }
            });
          }
        }
        if (this.delivery_destination == 1) {
          if (this.listContentId.length == 0) {
            error = true;
          } else {
            this.listContentId.forEach((item) => {
              if (item.length == 0 || !item.id || item.id.length == 0) {
                error = true;
              } else {
                let subContent = [];
                item.id.forEach((subItem) => {
                  subContent.push(subItem.content_id);
                });
                this.arr_content_id.push(subContent);
              }
            });
          }
        }

        const formCreate = {
          id: this.$route.params.id,
          shop_id: shop_id,
          list_content: this.arr_content_id,
          list_slag: this.arr_slag_id,
          delivery_destination: this.delivery_destination,
        };
        await this.checkConditionTimingMail(formCreate);

        this.listUserSendOptions = this.timingMailUserSend.map((item) => ({
          user_id: item.id,
          user_email: item.email,
          user_name: item.name,
        }));
      } else {
        this.listUserSendOptions = this.listUserOptions;
      }
      this.listUserSendTemp = this.listUserSendOptions.map((item) => ({
        user_id: item.user_id,
        user_name: item.user_name,
        user_email: item.user_email,
      }));
      if (error) {
        this.$toasted.error("必須項目のご入力をお願いします。");
        this.isLoading = false;
        return;
      }

      this.dateTimeSendId = setInterval(() => {
        if (this.use_timing_mail == 0) {
          this.dateTimeSend = moment(new Date()).format("YYYY年MM月DD日 HH:mm");
        } else if (this.use_timing_mail == 1) {
          this.dateTimeSend = moment(new Date(this.datetime)).format(
            "YYYY年MM月DD日 HH:mm"
          );
        } else {
          if (!this.typeHourMinute) {
            this.dateTimeSend = moment(new Date(), "YYYY-MM-DD HH:mm")
              .add(this.minutes, "minutes")
              .format("YYYY年MM月DD日 HH:mm");
          } else {
            this.dateTimeSend = moment(new Date(), "YYYY-MM-DD HH:mm")
              .add(this.hours, "hours")
              .format("YYYY年MM月DD日 HH:mm");
          }
        }
      }, 1000);
      console.log(this.dateTimeSendId);
      this.$bvModal.show("modal-send-user");
      this.isLoading = false;
    },

    async CreateTimeEmail() {
      let arr_user_send_id = [];
      if (this.listUserSendOptions.length) {
        this.listUserSendOptions.forEach((item) => {
          arr_user_send_id.push(item.user_id);
        });
      }
      if (this.delivery_destination == 0) {
        this.arr_content_id = [];
        this.arr_slag_id = [];
      } else if (this.delivery_destination == 1) {
        this.arr_user_id = [];
        this.arr_slag_id = [];
      } else if (this.delivery_destination == 2) {
        this.arr_user_id = [];
        this.arr_content_id = [];
      } else {
        this.arr_user_id = [];
        this.arr_content_id = [];
        this.arr_slag_id = [];
      }
      if (this.use_timing_mail == 0) {
        this.datetime = "";
        this.typeHourMinute = 0;
        this.minutes = "";
        this.hours = "";
      } else if (this.use_timing_mail == 1) {
        this.typeHourMinute = 0;
        this.minutes = "";
        this.hours = "";
      } else if (this.use_timing_mail == 2) {
        this.datetime = "";
      }

      var timeSend = moment(
        this.dateTimeSend.match(/[a-zA-Z0-9]/g).join(""),
        "YYYY-MM-DD HH:mm"
      );
      timeSend = moment(new Date(timeSend)).format("YYYY-MM-DD HH:mm");
      this.isLoading = true;
      let shop_id = this.shop_id;
      const formCreate = {
        id: this.$route.params.id,
        shop_id: shop_id,
        sender_name: this.sender_name,
        delivery_destination: this.delivery_destination,
        list_user: this.arr_user_id,
        list_content: this.arr_content_id,
        list_slag: this.arr_slag_id,
        subject: this.subject,
        content_type: this.content_type,
        content: this.content,
        use_timing_mail: this.use_timing_mail,
        datetime: this.datetime,
        type_hour_minute: this.typeHourMinute,
        minutes: this.minutes,
        hours: this.hours,
        time_send: timeSend,
        list_user_send: arr_user_send_id,
      };
      if (formCreate.id === undefined) {
        const request = await this.createTimingMail(formCreate);
        if (request.success === true) {
          this.$router.go(-1);
        } else {
          this.isLoading = false;
          this.$toasted.error(request.message);
        }
      } else {
        const request = await this.updateTimingMailByID(formCreate);
        if (request.success === true) {
          this.$router.go(-1);
        } else {
          this.isLoading = false;
          this.$toasted.error(request.message);
        }
      }
    },
    returnList() {
      this.$router.go(-1);
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg"
      ) {
        this.$toasted.error("画像がjpg、jpeg、png　形式である必要があります。");
      } else {
        if (file.size / 1024 / 1024 > 10) {
          this.$toasted.error("画像1枚につき10MBまで");
          resetUploader();
        } else {
          var formData = new FormData();
          formData.append("image", file);
          formData.append("id", this.shop_id);

          const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
          const method = "POST";
          await Api.adminRequestServer({
            method,
            url,
            data: formData,
          })
            .then((response) => {
              const url = response.data.data; // Get url from response
              Editor.insertEmbed(cursorLocation, "image", url);
              resetUploader();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    handleImageRemove(file) {
      const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
      const method = "POST";
      Api.adminRequestServer({
        method,
        url,
        data: file,
      });
    },
    toggleModal() {
      document.getElementById("idSelectFile").click();
    },
    async changePDF(e) {
      var formData = new FormData();
      formData.append("image", e.target.files[0]);
      formData.append("id", this.shop_id);
      if (e.target.files[0].size / 1024 / 1024 > 10) {
        this.$toasted.error("PDFファイルは10MBを超えこえてはなりません");
      } else {
        const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
        const method = "POST";
        await Api.adminRequestServer({
          method,
          url,
          data: formData,
        })
          .then((response) => {
            const url = response.data.data; // Get url from response
            this.content +=
              '<iframe class="ql-pdf" frameborder="0" allowfullscreen="true" src="' +
              url +
              '"></iframe><p><br></p>';
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    changeValueReceivingObject(id) {
      if (id !== 2) {
        this.minutes = "";
      }
    },
    addSlagId() {
      this.listSlagId.push({});
    },
    addContentId() {
      this.listContentId.push({});
    },
    showModal() {},
    closeModal() {
      this.$refs["modal-send-user"].hide();
      clearInterval(this.dateTimeSendId);
      this.keyword = "";
    },
    fomatObject(array, object) {
      if (array && array.length > 0) {
        let ArrrayObject = [];
        Object.keys(object)
          .filter((key) => array.includes(parseInt(key)))
          .reduce((obj, key) => {
            ArrrayObject.push(object[key]);
          }, {});
        return ArrrayObject;
      }
    },
    removeUserSend(index) {
      this.listUserSendOptions.splice(index, 1);
    },
    filterUserSend() {
      if (this.keyword) {
        var fields = ["user_email", "user_name"];
        this.listUserSendOptions = Object.values(
          this.listUserSendOptions
        ).filter((item) =>
          fields.some((field) => item[field].includes(this.keyword))
        );
      } else {
        this.listUserSendOptions = this.listUserSendTemp;
      }
    },
    removeListDisplay(index, value = null) {
      if (value === "slag") {
        this.listSlagId.splice(index, 1);
      } else {
        this.listContentId.splice(index, 1);
      }
    },
  },
};
</script>
<style scoped>
.create-form {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 0px !important;
}
.textMust {
  color: #fff;
  background: red;
  padding: 2px 0px;
  border-radius: 5px;
  top: 0px;
  margin-left: 15px;
  font-size: 11px;
}
.custom-text {
  background-color: rgb(213, 213, 213);
  height: 40px;
  align-items: center;
  display: flex;
}
.row-input input {
  margin: 0px !important;
}
.btn-toggle-active {
  display: inline-block;
  position: relative;
  .btn-checkbox-active {
    border: 0;
  }
  input[type="radio"] {
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    inset: 0;
    &:checked ~ .btn-checkbox-active {
      background: #ff644e;
      color: #fff;
    }
  }
}
.btn-toggle-active {
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
}
</style>
